import { BaseApi } from './base';
import { createRequestFunction } from './common';

export default class DmarcApi extends BaseApi {
  getFeedbackReports(
    start_date,
    end_date,
    from_domain,
    source_ip,
    isp_name,
    start,
    length
  ) {
    const localVarAxiosArgs = {
      url: `/dmarc/feedback_reports`,
      options: {
        method: 'GET',
        params: {
          start_date,
          end_date,
          from_domain,
          source_ip,
          isp_name,
          start,
          length,
        },
      },
    };

    return createRequestFunction(localVarAxiosArgs, this.axios);
  }

  getFeedbackReportDetails(domain_id, date_report) {
    const localVarAxiosArgs = {
      url: `/dmarc/feedback_report_details`,
      options: {
        method: 'GET',
        params: {
          domain_id,
          date_report,
        },
      },
    };

    return createRequestFunction(localVarAxiosArgs, this.axios);
  }

  getDmarcDomains() {
    const localVarAxiosArgs = {
      url: `/dmarc/dmarc_domains`,
      options: {
        method: 'GET',
      },
    };

    return createRequestFunction(localVarAxiosArgs, this.axios);
  }

  saveDmarcDomain(domain_name) {
    const localVarAxiosArgs = {
      url: `/dmarc/dmarc_domain`,
      options: {
        method: 'POST',
        data: {
          domain_name,
        },
      },
    };

    return createRequestFunction(localVarAxiosArgs, this.axios);
  }

  deleteDmarcDomain(domain_id) {
    const localVarAxiosArgs = {
      url: `/dmarc/dmarc_domain`,
      options: {
        method: 'DELETE',
        params: {
          domain_id,
        },
      },
    };

    return createRequestFunction(localVarAxiosArgs, this.axios);
  }

  generateDmarcDomain(dmarcData) {
    const localVarAxiosArgs = {
      url: `/dmarc/dmarc_record`,
      options: {
        method: 'POST',
        data: dmarcData,
      },
    };

    return createRequestFunction(localVarAxiosArgs, this.axios);
  }

  validateDmarcRecord(domain, dmarc_record) {
    const localVarAxiosArgs = {
      url: `/dmarc/validate_dmarc_record`,
      options: {
        method: 'POST',
        data: {
          domain,
          dmarc_record,
        },
      },
    };

    return createRequestFunction(localVarAxiosArgs, this.axios);
  }

  validateDmarcDomain(domain) {
    const localVarAxiosArgs = {
      url: `/dmarc/validate_dmarc_domain`,
      options: {
        method: 'POST',
        data: {
          domain,
        },
      },
    };

    return createRequestFunction(localVarAxiosArgs, this.axios);
  }
}
