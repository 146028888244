import { BaseApi } from './base';
import { createRequestFunction } from './common';

export default class SetupApi extends BaseApi {
  getFromEmail() {
    const localVarAxiosArgs = {
      url: `/setup/get_from_email`,
      options: {
        method: 'GET',
      },
    };

    return createRequestFunction(localVarAxiosArgs, this.axios);
  }

  getAll() {
    const localVarAxiosArgs = {
      url: `/setup/all`,
      options: {
        method: 'GET',
      },
    };

    return createRequestFunction(localVarAxiosArgs, this.axios);
  }

  updateValidationMethod(validation_method) {
    const localVarAxiosArgs = {
      url: `/setup/update_validation_method`,
      options: {
        method: 'POST',
        data: {
          validation_method,
        },
      },
    };

    return createRequestFunction(localVarAxiosArgs, this.axios);
  }

  getCurrentSetupStep() {
    const localVarAxiosArgs = {
      url: `/setup/current_setup_step`,
      options: {
        method: 'GET',
      },
    };

    return createRequestFunction(localVarAxiosArgs, this.axios);
  }

  updateCurrentSetupStep(current_setup_step) {
    const localVarAxiosArgs = {
      url: `/setup/current_setup_step`,
      options: {
        method: 'PUT',
        params: {
          current_setup_step,
        },
      },
    };

    return createRequestFunction(localVarAxiosArgs, this.axios);
  }

  updateRegions(data) {
    const localVarAxiosArgs = {
      url: `/setup/update_regions`,
      options: {
        method: 'POST',
        data,
      },
    };

    return createRequestFunction(localVarAxiosArgs, this.axios);
  }

  addNewFromEmail(from_email) {
    const localVarAxiosArgs = {
      url: `/setup/add_new_from_email`,
      options: {
        method: 'POST',
        data: {
          from_email,
        },
      },
    };

    return createRequestFunction(localVarAxiosArgs, this.axios);
  }

  deleteFromEmail(from_email_id) {
    const localVarAxiosArgs = {
      url: `/setup/delete_from_email`,
      options: {
        method: 'DELETE',
        data: {
          from_email_id,
        },
      },
    };

    return createRequestFunction(localVarAxiosArgs, this.axios);
  }

  updateListSetup(use_weighted) {
    const localVarAxiosArgs = {
      url: `/setup/update_list_setup`,
      options: {
        method: 'POST',
        data: { use_weighted },
      },
    };

    return createRequestFunction(localVarAxiosArgs, this.axios);
  }

  saveWeightedSeedListProportions(proportions) {
    const localVarAxiosArgs = {
      url: `/setup/save_weighted_seed_list_proportions`,
      options: {
        method: 'POST',
        data: {
          proportions,
        },
      },
    };

    return createRequestFunction(localVarAxiosArgs, this.axios);
  }
}
