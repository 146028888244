import { createRouter, createWebHistory } from 'vue-router';

import AccountLayout from '@/layouts/AccountLayout/AccountLayout';
import PageLayout from '@/layouts/PageLayout/PageLayout';

let serviceType = localStorage.getItem('service_type');
const isDmarcCustomer = serviceType ? serviceType.toString() === '100' : false;

const routes = [
  {
    path: '/account/signasadmin',
    name: 'Sign In',
    component: () => import('@/views/Account/SignAsAdmin'),
  },
  {
    path: '/account',
    component: AccountLayout,
    redirect: '/account/login',
    children: [
      {
        path: 'login',
        name: 'LogIn',
        component: () => import('@/views/Account/Login'),
      },
      {
        path: 'ForgotPassword',
        name: 'ForgotPassword',
        component: () => import('@/views/Account/ForgotPasword'),
      },
      {
        path: 'reset-password',
        name: 'ResetPassword',
        component: () => import('@/views/Account/ResetPassword'),
      },
      {
        path: 'PasswordChange',
        name: 'PasswordChange',
        component: () => import('@/views/Account/ResetPasswordChargeBee'),
      },
    ],
  },
  {
    path: '/delivery/shareable-report',
    name: 'DeliveryShareableReport',
    component: () => import('@/views/DeliveryShareableReport'),
  },
  {
    path: '/',
    component: PageLayout,
    redirect: '/account/login',
    children: [
      {
        path: 'overview',
        name: 'Overview',
        component: () =>
          isDmarcCustomer
            ? import('@/views/DmarcCustomerView')
            : import('@/views/Overview'),
      },

      // Delivery Monitors
      {
        path: '/delivery/report',
        name: 'Deliverability Report',
        component: () =>
          isDmarcCustomer
            ? import('@/views/DmarcCustomerView')
            : import('@/views/DeliveryMonitors/SeedTests'),
      },
      {
        path: '/delivery/isp-delivery-report',
        name: 'ISP Trends',
        component: () =>
          isDmarcCustomer
            ? import('@/views/DmarcCustomerView')
            : import('@/views/DeliveryMonitors/ISPTrends'),
      },
      {
        path: '/delivery/gmail-tabs',
        name: 'Gmail Tab Summary',
        component: () =>
          isDmarcCustomer
            ? import('@/views/DmarcCustomerView')
            : import('@/views/DeliveryMonitors/GmailTabs'),
      },

      // Reputation Monitors
      {
        path: '/reputation/summary',
        name: 'Reputation Summary',
        component: () =>
          isDmarcCustomer
            ? import('@/views/DmarcCustomerView')
            : import('@/views/ReputationMonitors/Summary'),
      },
      {
        path: '/reputation/rdns-summary',
        name: 'Reverse DNS Report Summary',
        component: () =>
          isDmarcCustomer
            ? import('@/views/DmarcCustomerView')
            : import('@/views/ReputationMonitors/RDNS'),
      },
      {
        path: '/reputation/microsoft-snds',
        name: 'Microsoft SNDS Report Summary',
        component: () =>
          isDmarcCustomer
            ? import('@/views/DmarcCustomerView')
            : import('@/views/ReputationMonitors/MicrosoftSNDS'),
      },
      {
        path: '/reputation/postmaster-reports',
        name: 'Google Postmaster Reports',
        component: () =>
          isDmarcCustomer
            ? import('@/views/DmarcCustomerView')
            : import('@/views/ReputationMonitors/GooglePostmasterReports'),
      },
      {
        path: '/reputation/postmaster-setup',
        name: 'Google Postmaster Setup',
        component: () =>
          isDmarcCustomer
            ? import('@/views/DmarcCustomerView')
            : import('@/views/ReputationMonitors/PostmasterSetup'),
      },
      {
        path: '/reputation/address-details',
        name: 'Reputation Details',
        component: () =>
          isDmarcCustomer
            ? import('@/views/DmarcCustomerView')
            : import('@/views/ReputationMonitors/AddressDetails'),
      },
      {
        path: '/reputation/setup',
        name: 'Reputation',
        component: () =>
          isDmarcCustomer
            ? import('@/views/DmarcCustomerView')
            : import('@/views/ReputationMonitors/ReputationSetup'),
      },

      // DMARC
      {
        path: '/dmarc/dmarc-summary',
        name: 'DMARC Summary',
        component: () => import('@/views/DMARC/Summary'),
      },
      {
        path: '/dmarc/record-generator',
        name: 'DMARC Record Generator',
        component: () => import('@/views/DMARC/RecordGenerator'),
      },
      {
        path: '/dmarc/checker',
        name: 'DMARC Domain Checker',
        component: () => import('@/views/DMARC/DomainChecker'),
      },
      {
        path: '/dmarc/setup',
        name: 'DMARC Setup',
        component: () => import('@/views/DMARC/Setup'),
      },

      // Engagement Monitors
      {
        path: '/opentracker/index',
        name: 'Opentracker Campaigns',
        component: () =>
          isDmarcCustomer
            ? import('@/views/DmarcCustomerView')
            : import('@/views/EngagementMonitors/Opentracker'),
      },
      {
        path: '/opentracker/summary',
        name: 'Opentrcker Summary',
        component: () =>
          isDmarcCustomer
            ? import('@/views/DmarcCustomerView')
            : import('@/views/EngagementMonitors/OpentrackerSummary'),
      },
      {
        path: '/opentracker/setup',
        name: 'Opentracker Setup',
        component: () =>
          isDmarcCustomer
            ? import('@/views/DmarcCustomerView')
            : import('@/views/EngagementMonitors/OpentrackerSetup'),
      },

      // Design Monitors
      {
        path: '/email-preview/preview-summary',
        name: 'Email Preview Summary',
        component: () =>
          isDmarcCustomer
            ? import('@/views/DmarcCustomerView')
            : import('@/views/DesignMonitors'),
      },

      // Alerts
      {
        path: '/alerts/index',
        name: 'Alerts',
        component: () =>
          isDmarcCustomer
            ? import('@/views/DmarcCustomerView')
            : import('@/views/Alerts/Alerts'),
      },
      {
        path: '/alerts/setup',
        name: 'Alert Setup',
        component: () =>
          isDmarcCustomer
            ? import('@/views/DmarcCustomerView')
            : import('@/views/Alerts/AlertSetup'),
      },

      // Account
      {
        path: '/account/sub-account',
        name: 'Sub-Accounts Administration',
        component: () =>
          isDmarcCustomer
            ? import('@/views/DmarcCustomerView')
            : import('@/views/Account/SubAccount'),
      },
      {
        path: '/account/extra-login',
        name: 'Manage User Logins',
        component: () =>
          isDmarcCustomer
            ? import('@/views/DmarcCustomerView')
            : import('@/views/Account/ExtraLogin'),
      },
      {
        path: '/account/update',
        name: 'Account Update',
        component: () => import('@/views/Account/Update'),
      },
      {
        path: '/account/change-password',
        name: 'Change Password',
        component: () => import('@/views/Account/ChangePassword'),
      },
      {
        path: '/sub-account/reset',
        name: 'Sub Account Password Update',
        component: () =>
          isDmarcCustomer
            ? import('@/views/DmarcCustomerView')
            : import('@/views/Account/SubAccountPasswordUpdate'),
      },

      // Setup
      {
        path: '/setup/all',
        name: 'Setup',
        component: () =>
          isDmarcCustomer
            ? import('@/views/DmarcCustomerView')
            : import('@/views/Setup/All'),
      },

      // Other Tools
      {
        path: '/other-tools',
        name: 'OtherTools',
        component: () =>
          isDmarcCustomer
            ? import('@/views/DmarcCustomerView')
            : import('@/views/OtherTools'),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  document.title = `MailMonitor | ${to.name}`;
  const isLogin = localStorage.getItem('isLogin');

  if (
    to.name === 'DeliveryShareableReport' ||
    to.path === '/account/signasadmin'
  ) {
    next();
    return;
  }

  if (
    ['LogIn', 'ForgotPassword', 'ResetPassword', 'PasswordChange'].includes(
      to.name
    )
  ) {
    if (isLogin === 'true') next({ path: '/overview' });
    else next();
    return;
  }

  if (isLogin === 'true') next();
  else next({ path: '/account/login' });
});

export default router;
