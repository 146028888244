import { BaseApi } from './base';
import { createRequestFunction } from './common';

export default class DeliveryApi extends BaseApi {
  getGmailTabs(
    start_date,
    end_date,
    domain,
    email_subject,
    corporate_campaign_id_filter
  ) {
    const localVarAxiosArgs = {
      url: `/tab/tab_summary`,
      options: {
        method: 'GET',
        params: {
          start_date,
          end_date,
          domain,
          email_subject,
          corporate_campaign_id_filter,
        },
      },
    };

    return createRequestFunction(localVarAxiosArgs, this.axios);
  }

  getDeliverySummary(
    start_date,
    end_date,
    domain_filter,
    campaign_id_filter,
    report_id_filter,
    min_delivery,
    delivery_rate,
    page_size,
    page_num
  ) {
    const localVarAxiosArgs = {
      url: `/delivery/delivery_summary`,
      options: {
        method: 'GET',
        params: {
          start_date,
          end_date,
          domain_filter,
          campaign_id_filter,
          report_id_filter,
          min_delivery,
          delivery_rate,
          page_size,
          page_num,
        },
      },
    };

    return createRequestFunction(localVarAxiosArgs, this.axios);
  }

  getDeliveryEmailDetails(report_id) {
    const localVarAxiosArgs = {
      url: `/delivery/`,
      options: {
        method: 'GET',
        params: {
          report_id,
        },
      },
    };

    return createRequestFunction(localVarAxiosArgs, this.axios);
  }

  getGlobalIspReport(
    start_date,
    end_date,
    domain_filter,
    campaign_id,
    max_deliver,
    min_deliver
  ) {
    const localVarAxiosArgs = {
      url: `/delivery/global_isp_report`,
      options: {
        method: 'GET',
        params: {
          start_date,
          end_date,
          domain_filter,
          campaign_id,
          max_deliver,
          min_deliver,
        },
      },
    };

    return createRequestFunction(localVarAxiosArgs, this.axios);
  }

  getIspReport(report_id) {
    const localVarAxiosArgs = {
      url: `/delivery/isp_report`,
      options: {
        method: 'GET',
        params: {
          report_id,
        },
      },
    };

    return createRequestFunction(localVarAxiosArgs, this.axios);
  }

  getShareableLink(report_id) {
    const localVarAxiosArgs = {
      url: `/delivery/shareable_link`,
      options: {
        method: 'GET',
        params: {
          report_id,
        },
      },
    };

    return createRequestFunction(localVarAxiosArgs, this.axios);
  }

  getEmailReport(report_id) {
    const localVarAxiosArgs = {
      url: `/delivery/email_report`,
      options: {
        method: 'GET',
        params: {
          report_id,
        },
      },
    };

    return createRequestFunction(localVarAxiosArgs, this.axios);
  }

  getHeaderForReportIsp(report_id, isp_id) {
    const localVarAxiosArgs = {
      url: `/delivery/header_for_report_isp`,
      options: {
        method: 'GET',
        params: {
          report_id,
          isp_id,
        },
      },
    };

    return createRequestFunction(localVarAxiosArgs, this.axios);
  }

  getIspDeliveryDateCount(
    start_date,
    end_date,
    domain_filter,
    isp_id,
    max_deliver,
    min_deliver
  ) {
    const localVarAxiosArgs = {
      url: `/delivery/isp_delivery_date_count`,
      options: {
        method: 'GET',
        params: {
          start_date,
          end_date,
          domain_filter,
          isp_id,
          max_deliver,
          min_deliver,
        },
      },
    };

    return createRequestFunction(localVarAxiosArgs, this.axios);
  }

  getIsps() {
    const localVarAxiosArgs = {
      url: `/delivery/isps`,
      options: {
        method: 'GET',
      },
    };

    return createRequestFunction(localVarAxiosArgs, this.axios);
  }

  getIspDetailsByCampaignDate(
    start_date,
    end_date,
    campaign_date,
    delivery_rate,
    min_delivery,
    domain_filter,
    isp_id
  ) {
    const localVarAxiosArgs = {
      url: `/delivery/isp_details_by_campaign_date`,
      options: {
        method: 'GET',
        params: {
          start_date,
          end_date,
          campaign_date,
          delivery_rate,
          min_delivery,
          domain_filter,
          isp_id,
        },
      },
    };

    return createRequestFunction(localVarAxiosArgs, this.axios);
  }

  getDeliveryShareableReport(token) {
    const localVarAxiosArgs = {
      url: `/delivery/shareable_report`,
      options: {
        method: 'GET',
        params: {
          token,
        },
      },
    };

    return createRequestFunction(localVarAxiosArgs, this.axios);
  }

  getSeedsForReportIsp(report_id, isp_id) {
    const localVarAxiosArgs = {
      url: `/delivery/seeds_for_report_isp`,
      options: {
        method: 'POST',
        data: {
          report_id,
          isp_id,
        },
      },
    };

    return createRequestFunction(localVarAxiosArgs, this.axios);
  }

  getEmailHeaderById(email_big_id) {
    const localVarAxiosArgs = {
      url: `/delivery/email_header_by_id`,
      options: {
        method: 'GET',
        params: {
          email_big_id,
        },
      },
    };

    return createRequestFunction(localVarAxiosArgs, this.axios);
  }
}
