import { BaseApi } from './base';
import { createRequestFunction } from './common';

export default class OverviewApi extends BaseApi {
  getOverviewSummary(start_date, end_date) {
    const localVarAxiosArgs = {
      url: `/overview/summary`,
      options: {
        method: 'GET',
        params: {
          start_date,
          end_date,
        },
      },
    };

    return createRequestFunction(localVarAxiosArgs, this.axios);
  }
}
