import { BaseApi } from './base';
import { createRequestFunction } from './common';

export default class OpentrackerApi extends BaseApi {
  getOpentrackerCampaingsCount(start_date, end_date, start, length) {
    const localVarAxiosArgs = {
      url: `/opentracker/opentracker_campaigns_count`,
      options: {
        method: 'GET',
        params: {
          start_date,
          end_date,
          start,
          length,
        },
      },
    };

    return createRequestFunction(localVarAxiosArgs, this.axios);
  }

  getCountryOpenEventCount(
    start_date,
    end_date,
    corporate_campaign_id_filter,
    customer_filter
  ) {
    const localVarAxiosArgs = {
      url: `/opentracker/country_open_event_count`,
      options: {
        method: 'GET',
        params: {
          start_date,
          end_date,
          corporate_campaign_id_filter,
          customer_filter,
        },
      },
    };

    return createRequestFunction(localVarAxiosArgs, this.axios);
  }

  getDeviceCategoryOpenEventCount(
    start_date,
    end_date,
    corporate_campaign_id_filter,
    customer_filter
  ) {
    const localVarAxiosArgs = {
      url: `/opentracker/device_category_open_event_count`,
      options: {
        method: 'GET',
        params: {
          start_date,
          end_date,
          corporate_campaign_id_filter,
          customer_filter,
        },
      },
    };

    return createRequestFunction(localVarAxiosArgs, this.axios);
  }

  getOpenEvents(
    start_date,
    end_date,
    start,
    length,
    corporate_campaign_id_filter,
    customer_filter
  ) {
    const localVarAxiosArgs = {
      url: `/opentracker/open_events`,
      options: {
        method: 'GET',
        params: {
          start_date,
          end_date,
          start,
          length,
          corporate_campaign_id_filter,
          customer_filter,
        },
      },
    };

    return createRequestFunction(localVarAxiosArgs, this.axios);
  }

  getDeliveryAndDailyOpenEventSummary(
    start_date,
    end_date,
    corporate_campaign_id_filter,
    customer_filter
  ) {
    const localVarAxiosArgs = {
      url: `/opentracker/delivery_and_daily_open_event_summary`,
      options: {
        method: 'GET',
        params: {
          start_date,
          end_date,
          corporate_campaign_id_filter,
          customer_filter,
        },
      },
    };

    return createRequestFunction(localVarAxiosArgs, this.axios);
  }
}
