<template>
  <div v-if="notificationTitle !== null">
    <a-row>
      <a-col :span="24">
        <div
          class="notification-banner"
          :style="{ backgroundColor: notificationColor }"
        >
          <span @click="showOverlay" class="notification-text">
            {{ notificationTitle }}
          </span>
          <a-button
            type="text"
            class="close-button"
            @click="acknowledgeNotification"
          >
            &times;
          </a-button>
        </div>

        <a-modal
          :open="notificationOverlayVisible"
          :title="notificationTitle"
          @cancel="closeOverlay"
        >
          <div v-html="notificationContent"></div>
          <template #footer>
            <a-config-provider :theme="getSpecialTheme()">
              <a-button type="primary" @click="acknowledgeNotification">
                Dismiss
              </a-button>
            </a-config-provider>
          </template>
        </a-modal>
      </a-col>
    </a-row>
  </div>
</template>

<script>
// import { CloseOutlined } from '@ant-design/icons-vue';
import { defineComponent, onMounted, ref } from 'vue';
import { useStore } from 'vuex';

import api from '@/api';
import { STORE_EVENT } from '@/store';

export default defineComponent({
  name: 'Notification-Component',
  components: {
    // CloseOutlined,
  },
  setup() {
    // const router = useRouter();
    const store = useStore();
    const notificationId = ref(0);
    const notificationTitle = ref(null);
    const notificationContent = ref(null);
    const notificationColor = ref('red');
    const notificationOverlayVisible = ref(false);

    const showOverlay = () => {
      notificationOverlayVisible.value = true;
    };

    const closeOverlay = () => {
      // emit('close');
      notificationOverlayVisible.value = false;
    };

    const getNotification = () => {
      store.dispatch(STORE_EVENT.START_LOADING_PAGE);

      api.NotificationApi.getNotification()
        .then((data) => {
          if (
            data.notification_id != null ||
            data.notification_id != undefined
          ) {
            notificationId.value = data.notification_id;
            notificationTitle.value = data.message_title;
            notificationContent.value = data.message_detail;
            notificationColor.value = data.message_color;
          }
        })
        .finally(() => {
          store.dispatch(STORE_EVENT.STOP_LOADING_PAGE);
        });
    };

    const acknowledgeNotification = () => {
      notificationOverlayVisible.value = false;
      notificationTitle.value = null;
      store.dispatch(STORE_EVENT.START_LOADING_PAGE);

      api.NotificationApi.acknowledgeHandler(notificationId.value)
        .then(() => {
          getNotification();
        })
        .finally(() => {
          store.dispatch(STORE_EVENT.STOP_LOADING_PAGE);
        });
    };

    onMounted(() => {
      getNotification();
    });

    return {
      notificationTitle,
      notificationContent,
      notificationColor,
      notificationOverlayVisible,
      showOverlay,
      closeOverlay,
      acknowledgeNotification,
    };
  },
});
</script>

<style lang="less" scoped>
@import './Notification.less';
</style>
