<template>
  <a-spin size="large" :spinning="loadingPage">
    <div class="login-container">
      <div class="middle-box loginscreen animated fadeInDown">
        <div class="brand">
          <img src="@/assets/img/mm-new-black-logo.svg" />
        </div>
        <router-view />
        <p class="legal">Copyright MailMonitor &copy; {{ currentYear }}</p>
      </div>
      <div class="login-background"></div>
    </div>
  </a-spin>
</template>

<script>
import dayjs from 'dayjs';
import { computed, defineComponent, ref } from 'vue';
import { useStore } from 'vuex';

export default defineComponent({
  name: 'AccountLayout',
  setup() {
    const store = useStore();
    const currentYear = ref(dayjs().year());

    return {
      loadingPage: computed(() => store.state.loadingAccountPage),
      currentYear,
    };
  },
});
</script>

<style lang="less">
@import './AccountLayout.less';
</style>
