import { BaseApi } from './base';
import { createRequestFunction } from './common';

export default class EmailPreviewApi extends BaseApi {
  getEmailPreviewSummary(start_date, end_date) {
    const localVarAxiosArgs = {
      url: `/emailpreview/emailpreview_summary`,
      options: {
        method: 'GET',
        params: {
          start_date,
          end_date,
        },
      },
    };

    return createRequestFunction(localVarAxiosArgs, this.axios);
  }

  getEmailDetails(email_id) {
    const localVarAxiosArgs = {
      url: `/emailpreview/email_details`,
      options: {
        method: 'GET',
        params: {
          email_id,
        },
      },
    };

    return createRequestFunction(localVarAxiosArgs, this.axios);
  }
}
