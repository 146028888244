import { BaseApi } from './base';
import { createRequestFunction } from './common';

export default class AbusixApi extends BaseApi {
  getResultsByEmailDomain(domain, ip, begin, end) {
    const localVarAxiosArgs = {
      url: `/abusix/email_domain_and_ip`,
      options: {
        method: 'POST',
        data: {
          begin,
          end,
          domain,
          ip,
        },
      },
    };

    return createRequestFunction(localVarAxiosArgs, this.axios);
  }

  getResultsByEmailIP(ip, begin, end) {
    const localVarAxiosArgs = {
      url: `/abusix/email_ip`,
      options: {
        method: 'POST',
        data: {
          begin,
          end,
          ip,
        },
      },
    };

    return createRequestFunction(localVarAxiosArgs, this.axios);
  }

  getReputationSummaryDateCount(domain, begin, end) {
    const localVarAxiosArgs = {
      url: `/abusix/spamtrap_summary_date`,
      options: {
        method: 'POST',
        data: {
          domain,
          begin,
          end,
        },
      },
    };

    return createRequestFunction(localVarAxiosArgs, this.axios);
  }

  getAddressReputationDetail(domain, begin, end) {
    const localVarAxiosArgs = {
      url: `/abusix/address_reputation_detail`,
      options: {
        method: 'POST',
        data: {
          domain,
          begin,
          end,
        },
      },
    };

    return createRequestFunction(localVarAxiosArgs, this.axios);
  }
}
