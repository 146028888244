import { BaseApi } from './base';
import { createRequestFunction } from './common';

export default class RdnsApi extends BaseApi {
  getRdnsSummary() {
    const localVarAxiosArgs = {
      url: `/rdns/rdns_summary`,
      options: {
        method: 'GET',
      },
    };

    return createRequestFunction(localVarAxiosArgs, this.axios);
  }

  addDomain(domain) {
    const localVarAxiosArgs = {
      url: `/rdns/domain`,
      options: {
        method: 'POST',
        data: {
          domain,
        },
      },
    };

    return createRequestFunction(localVarAxiosArgs, this.axios);
  }

  deleteDomain(rdns_id) {
    const localVarAxiosArgs = {
      url: `/rdns/domain/${rdns_id}`,
      options: {
        method: 'DELETE',
      },
    };

    return createRequestFunction(localVarAxiosArgs, this.axios);
  }
}
