<template>
  <a-config-provider :theme="{ token: { borderRadius: 0 } }">
    <router-view />
  </a-config-provider>
</template>

<script setup>
import { BarChart, LineChart, PieChart } from 'echarts/charts';
import {
  GridComponent,
  LegendComponent,
  TitleComponent,
  TooltipComponent,
} from 'echarts/components';
import { use } from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';

use([
  CanvasRenderer,
  PieChart,
  LineChart,
  BarChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
]);
</script>

<style lang="less">
@import '@/assets/css/animate.less';
@import '@/assets/css/date-range-picker.less';
@import '@/assets/css/theme.less';
@import '@/assets/css/style.less';
@import '@/assets/css/newcss.less';
@import '@/assets/css/global.less';
</style>

<style>
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700');
@import url('https://fonts.googleapis.com/css?family=Roboto:400,300,500,700');
@import url('./assets/font-awesome/css/font-awesome.css');

@font-face {
  font-family: 'Glamour';
  src: url('./assets/fonts/Glamour/GlamourAbsolute-regular.otf')
    format('opentype');
}

@font-face {
  font-family: 'Montserrat';
  src: url('./assets/fonts/Montserrat/Montserrat-Regular.ttf')
    format('opentype');
}

body * {
  font-family: 'Montserrat', Arial, sans-serif;
}

.ant-table-thead > tr > th {
  font-weight: 700;
}

.ant-drawer-title {
  font-size: 34px !important;
  font-family: 'Glamour';
  line-height: 58px !important;
  font-weight: normal !important;
}

p {
  margin-top: 0;
}

#launcher {
  z-index: 10 !important;
}
</style>
