import Antd from 'ant-design-vue';
import dayjs from 'dayjs';
import { createApp } from 'vue';
import VChart from 'vue-echarts';
import Vue3GoogleLogin from 'vue3-google-login';

import DateRangePicker from '@/components/DateRangePicker/DateRangePicker';
import TablePagination from '@/components/TablePagination/TablePagination';

import App from './App.vue';
import router from './router';
import store from './store';

createApp(App)
  .mixin({
    methods: {
      formatDateTime(dateStr, formatType = 'MM/DD/YYYY hh:mm:ss') {
        if (!dateStr) return '';

        return dayjs(dateStr).format(formatType);
      },
      getSpecialTheme() {
        return { token: { colorPrimary: '#F9A334' } };
      },
      getFormattedDecimal(value) {
        return Number(value).toFixed(2);
      },
    },
  })
  .component('date-range-picker', DateRangePicker)
  .component('table-pagination', TablePagination)
  .component('v-chart', VChart)
  .use(store)
  .use(router)
  .use(Antd)
  .use(Vue3GoogleLogin, {
    clientId:
      '299343356656-upqpm5h6ntkla4ar6v0tqoquhtkhpvip.apps.googleusercontent.com',
  })
  .mount('#app');
