import { BaseApi } from './base';
import { createRequestFunction } from './common';

export default class ReputationApi extends BaseApi {
  getAllDomainOrIp() {
    const localVarAxiosArgs = {
      url: `/reputation/all_domain_or_ip`,
      options: {
        method: 'GET',
      },
    };

    return createRequestFunction(localVarAxiosArgs, this.axios);
  }

  getSummary(page_num, page_size) {
    const localVarAxiosArgs = {
      url: `/reputation/summary`,
      options: {
        method: 'GET',
        params: {
          page_num,
          page_size,
        },
      },
    };

    return createRequestFunction(localVarAxiosArgs, this.axios);
  }

  getSummaryV2Filtered(page_num, page_size, search_params) {
    const search_operator =
      search_params.hitsFilter === 'greater'
        ? '>='
        : search_params.hitsFilter === 'less'
        ? '<='
        : '=';

    const post_params = {
      dnsbl: search_params.dnsbl === true ? 1 : 0,
      spamtrap: search_params.spamtrap === true ? 1 : 0,
      searchterm: search_params.searchName,
      operator: search_operator,
      quantity: search_params.hitsValue,
    };

    const localVarAxiosArgs = {
      url: `/reputation/summary_v2_filtered`,
      options: {
        method: 'POST',
        params: {
          page_num,
          page_size,
        },
        data: post_params,
      },
    };

    return createRequestFunction(localVarAxiosArgs, this.axios);
  }

  addDomainOrIp(domain, label) {
    const localVarAxiosArgs = {
      url: `/reputation/domain_or_ip`,
      options: {
        method: 'POST',
        params: {
          domain,
          label,
        },
      },
    };

    return createRequestFunction(localVarAxiosArgs, this.axios);
  }

  deleteDomainOrIp(id, domain) {
    const localVarAxiosArgs = {
      url: `/reputation/domain_or_ip`,
      options: {
        method: 'DELETE',
        params: {
          id,
          domain,
        },
      },
    };

    return createRequestFunction(localVarAxiosArgs, this.axios);
  }

  getBlocklist() {
    const localVarAxiosArgs = {
      url: `/reputation/blocklist`,
      options: {
        method: 'GET',
      },
    };

    return createRequestFunction(localVarAxiosArgs, this.axios);
  }

  getTrafficStatsByDomainId(domain_id, start_date, end_date) {
    const localVarAxiosArgs = {
      url: `/reputation/traffic_stats_by_domain_id`,
      options: {
        method: 'GET',
        params: {
          domain_id,
          start_date,
          end_date,
        },
      },
    };

    return createRequestFunction(localVarAxiosArgs, this.axios);
  }

  getReputationSummaryDateCount(address_id, start_date, end_date) {
    const localVarAxiosArgs = {
      url: `/reputation/reputation_summary_date_count`,
      options: {
        method: 'GET',
        params: {
          address_id,
          start_date,
          end_date,
        },
      },
    };

    return createRequestFunction(localVarAxiosArgs, this.axios);
  }

  getAddressReputationDetail(address_id, start_date, end_date) {
    const localVarAxiosArgs = {
      url: `/reputation/address_reputation_detail`,
      options: {
        method: 'GET',
        params: {
          address_id,
          start_date,
          end_date,
        },
      },
    };

    return createRequestFunction(localVarAxiosArgs, this.axios);
  }
}
