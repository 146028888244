<template>
  <a-layout-header style="background-color: #fff; padding: 0">
    <menu-unfold-outlined
      v-if="collapsed"
      class="trigger"
      @click="handleCollapsed"
    />
    <menu-fold-outlined v-else class="trigger" @click="handleCollapsed" />
    <div class="header-title">{{ pageName }}</div>

    <a-dropdown :trigger="['click']">
      <a class="profile">
        Hello, {{ user.customer_name }}&nbsp;
        <i class="fa fa-chevron-down" />
      </a>
      <template #overlay>
        <a-menu>
          <a-menu-item @click="handleAccount">Account</a-menu-item>
          <a-sub-menu
            v-if="parentUser && parentUser.service_type == 104"
            title="Switch Account"
          >
            <a-menu-item
              @click="handleSignInSubAccount(parentUser.primary_contact_email)"
            >
              {{ parentUser.customer_name }}
            </a-menu-item>
            <a-menu-item
              v-for="subAccount in subAccounts"
              :key="subAccount.customer_id"
              @click="handleSignInSubAccount(subAccount.primary_contact_email)"
            >
              {{ subAccount.company_name }}
            </a-menu-item>
          </a-sub-menu>
          <a-menu-item
            v-if="user.service_type && user.service_type == 104"
            @click="handleSubAccounts"
          >
            Sub-Accounts
          </a-menu-item>
          <a-menu-item
            v-if="
              user.service_type &&
              (user.service_type == 103 ||
                user.service_type == 104 ||
                customerPreference.enable_extra_login_flag == 1)
            "
            @click="handleManageUserLogins"
          >
            Manage User Logins
          </a-menu-item>
          <template v-if="user.service_type && user.service_type != 100">
            <a-sub-menu title="Setup">
              <a-menu-item @click="handleChangeRoute('/setup/all')">
                Seed Setup
              </a-menu-item>
              <a-menu-item @click="handleChangeRoute('/reputation/setup')">
                Reputation Setup
              </a-menu-item>
              <a-menu-item @click="handleChangeRoute('/alerts/setup')">
                Alert Setup
              </a-menu-item>
            </a-sub-menu>
            <!-- <a-sub-menu title="Help">
              <a-menu-item>FAQ</a-menu-item>
              <a-menu-item @click="handleChangeRoute('/other-tools')">
                Other Tools
              </a-menu-item>
            </a-sub-menu> -->
          </template>
          <a-menu-item @click="handleLogout">Logout</a-menu-item>
        </a-menu>
      </template>
    </a-dropdown>
  </a-layout-header>
</template>

<script>
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons-vue';
import { computed, defineComponent, onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

import api from '@/api';
import { STORE_EVENT } from '@/store';
import { convertDateToString, getInitialSearchDateRange } from '@/utils/util';

export default defineComponent({
  name: 'Header-Component',
  props: ['collapsed'],
  components: {
    MenuUnfoldOutlined,
    MenuFoldOutlined,
  },
  setup(props, { emit }) {
    const router = useRouter();
    const store = useStore();
    const subAccounts = ref([]);
    const user = ref({});
    const customerPreference = ref({});
    const parentUser = ref(null);
    const searchParams = ref({
      dateRange: getInitialSearchDateRange(store),
    });

    const handleCollapsed = () => {
      emit('handleCollapsed');
    };

    onMounted(() => {
      subAccounts.value = JSON.parse(localStorage.getItem('subaccounts'));
      user.value = JSON.parse(localStorage.getItem('user'));
      customerPreference.value = JSON.parse(
        localStorage.getItem('customer_preference')
      );
      parentUser.value = JSON.parse(localStorage.getItem('parent_user'));

      getUserTimezone();
    });

    const handleSubAccounts = () => {
      router.push('/account/sub-account');
    };

    const getUserTimezone = () => {
      const {
        dateRange: { startDate, endDate },
      } = searchParams.value;

      store.dispatch(STORE_EVENT.START_LOADING_PAGE);

      api.UserApi.me(
        `${convertDateToString(startDate)} 00:00:00`,
        `${convertDateToString(endDate)} 23:59:59`
      )
        .then((res) => {
          localStorage.setItem(
            'time_zone_pdt_offset',
            res.time_zone_pdt_offset
          );
        })
        .finally(() => {
          store.dispatch(STORE_EVENT.STOP_LOADING_PAGE);
        });
    };

    const handleManageUserLogins = () => {
      router.push('/account/extra-login');
    };

    const handleLogout = () => {
      localStorage.clear();
      window.location.replace('/');
    };

    const handleSignInSubAccount = (email) => {
      store.dispatch(STORE_EVENT.START_LOADING_PAGE);

      api.UserApi.signinSubaccount(email)
        .then((data) => {
          localStorage.setItem('user', JSON.stringify(data.user));
          localStorage.setItem(
            'customer_preference',
            JSON.stringify(data.customer_preference)
          );
          localStorage.setItem('current_setup_step', data.current_setup_step);
          localStorage.setItem('parent_user', JSON.stringify(data.parent_user));
          localStorage.setItem(
            'subaccounts',
            JSON.stringify(data.sub_accounts)
          );
          window.location.replace('/delivery/report');
        })
        .finally(() => {
          store.dispatch(STORE_EVENT.STOP_LOADING_PAGE);
        });
    };

    const handleAccount = () => {
      router.push('/account/update');
    };

    const handleChangeRoute = (path) => {
      router.push(path);
    };

    return {
      pageName: computed(() => store.state.pageName),
      subAccounts,
      user,
      customerPreference,
      parentUser,
      handleSubAccounts,
      handleCollapsed,
      handleLogout,
      handleSignInSubAccount,
      handleManageUserLogins,
      handleAccount,
      handleChangeRoute,
    };
  },
});
</script>

<style lang="less" scoped>
@import './Header.less';
</style>
