import { BaseApi } from './base';
import { createRequestFunction } from './common';

export default class SndsApi extends BaseApi {
  getSndsSummary(start_date, end_date, trap_hits, filter_color, ip_address) {
    const localVarAxiosArgs = {
      url: `/snds/snds_summary`,
      options: {
        method: 'GET',
        params: {
          start_date,
          end_date,
          trap_hits,
          filter_color,
          ip_address,
        },
      },
    };

    return createRequestFunction(localVarAxiosArgs, this.axios);
  }

  getSndsUrl() {
    const localVarAxiosArgs = {
      url: `/snds/snds_url`,
      options: {
        method: 'GET',
      },
    };

    return createRequestFunction(localVarAxiosArgs, this.axios);
  }

  insertSndsUrl(token) {
    const localVarAxiosArgs = {
      url: `/snds/snds_url`,
      options: {
        method: 'POST',
        data: {
          token,
        },
      },
    };

    return createRequestFunction(localVarAxiosArgs, this.axios);
  }

  deleteSndsUrl(snds_token_id) {
    const localVarAxiosArgs = {
      url: `/snds/snds_url/${snds_token_id}`,
      options: {
        method: 'DELETE',
      },
    };

    return createRequestFunction(localVarAxiosArgs, this.axios);
  }

  getGptDomains() {
    const localVarAxiosArgs = {
      url: `/snds/gpt_domains`,
      options: {
        method: 'GET',
      },
    };

    return createRequestFunction(localVarAxiosArgs, this.axios);
  }

  saveGptDomain(domain_name) {
    const localVarAxiosArgs = {
      url: `/snds/gpt_domain`,
      options: {
        method: 'POST',
        data: {
          domain_name,
        },
      },
    };

    return createRequestFunction(localVarAxiosArgs, this.axios);
  }

  deleteGptDomain(domain_id) {
    const localVarAxiosArgs = {
      url: `/snds/gpt_domain`,
      options: {
        method: 'DELETE',
        params: {
          domain_id,
        },
      },
    };

    return createRequestFunction(localVarAxiosArgs, this.axios);
  }
}
