<template>
  <a-row class="table-pagination">
    <a-col :span="8" class="total-count">
      {{ totalCountLabel }} : {{ totalCount }}
    </a-col>
    <a-col :span="16">
      <div class="pull-right flex items-center">
        <slot name="extra"></slot>
        <a-pagination
          class="ml-10"
          :current="pageNum"
          :pageSize="pageSize"
          :total="totalCount"
          show-size-changer
          @change="handleChangePagination"
        />
      </div>
    </a-col>
  </a-row>
</template>

<script>
import { defineComponent } from 'vue';
import { useStore } from 'vuex';

import { STORE_EVENT } from '@/store';
import { scrollToTopOfMain } from '@/utils/util';

export default defineComponent({
  props: {
    pageNum: {
      type: Number,
      required: true,
    },
    pageSize: {
      type: Number,
      required: true,
    },
    totalCount: {
      type: Number,
      required: true,
    },
    totalCountLabel: {
      type: String,
      required: true,
    },
  },
  setup(props, { emit }) {
    const store = useStore();

    const handleChangePagination = (num, size) => {
      store.dispatch(STORE_EVENT.CHANGE_PAGE_SIZE, size);
      scrollToTopOfMain();
      emit('update', { num, size });
    };

    return {
      handleChangePagination,
    };
  },
});
</script>

<style lang="less" scoped>
@import './TablePagination.less';
</style>
