import { createStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate';

import { initialSearchDate } from '@/utils/util';

export const STORE_EVENT = {
  START_LOADING_PAGE: 'startLoadingPage',
  STOP_LOADING_PAGE: 'stopLoadingPage',
  START_LOADING_ACCOUNT_PAGE: 'startLoadingAccountPage',
  STOP_LOADING_ACCOUNT_PAGE: 'stopLoadingAccountPage',
  CHANGE_PAGE_NAME: 'changePageName',
  CHANGE_SEARCH_DATE_RANGE: 'changeSearchDateRange',
  CHANGE_PAGE_SIZE: 'changePageSize',
  UPDATE_USER: 'updateUser',
};

export default createStore({
  state: {
    pageName: '',
    pageSize: 10,
    loadingPage: false,
    loadingAccountPage: false,
    searchDateRange: initialSearchDate(),
    user: {},
  },
  plugins: [createPersistedState({ paths: ['searchDateRange'] })],
  mutations: {
    CHANGE_PAGE_NAME(state, payload) {
      state.pageName = payload;
    },
    LOADING_PAGE(state, payload) {
      state.loadingPage = payload;
    },
    LOADING_ACCOUNT_PAGE(state, payload) {
      state.loadingAccountPage = payload;
    },
    CHANGE_SEARCH_DATE_RANGE(state, payload) {
      state.searchDateRange = payload;
    },
    UPDATE_USER(state, payload) {
      state.user = payload;
    },
    CHANGE_PAGE_SIZE(state, payload) {
      state.pageSize = payload;
    },
  },
  actions: {
    changePageName(context, payload) {
      context.commit('CHANGE_PAGE_NAME', payload);
    },
    startLoadingPage(context) {
      context.commit('LOADING_PAGE', true);
    },
    stopLoadingPage(context) {
      context.commit('LOADING_PAGE', false);
    },
    startLoadingAccountPage(context) {
      context.commit('LOADING_ACCOUNT_PAGE', true);
    },
    stopLoadingAccountPage(context) {
      context.commit('LOADING_ACCOUNT_PAGE', false);
    },
    changeSearchDateRange(context, payload) {
      context.commit('CHANGE_SEARCH_DATE_RANGE', payload);
    },
    updateUser(context, payload) {
      context.commit('UPDATE_USER', payload);
    },
    changePageSize(context, payload) {
      context.commit('CHANGE_PAGE_SIZE', payload);
    },
  },
});
