import { BaseApi } from './base';
import { createRequestFunction } from './common';

export default class NotificationApi extends BaseApi {
  getNotification() {
    const localVarAxiosArgs = {
      url: `/notifications/notification`,
      options: {
        method: 'GET',
      },
    };

    return createRequestFunction(localVarAxiosArgs, this.axios);
  }

  acknowledgeHandler(notification_id) {
    const localVarAxiosArgs = {
      url: `/notifications/acknowledge`,
      options: {
        method: 'POST',
        data: {
          notification_id,
        },
      },
    };

    return createRequestFunction(localVarAxiosArgs, this.axios);
  }
}
